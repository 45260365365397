// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData, getTranslatableInputValues, getTranslationData, initTranslations,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreatePartner, GetPartnerById, UpdatePartnersById} from "../../redux/actions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {validateTranslations} from "../../utils/validateTranslations";
import {Editor} from "../../components/uiElements/Editor";
import {Switch} from "antd";

class AddEditPartner extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description`',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                icon: undefined,
                isHidden: false,
                link: '',
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
            mediaModalType: '',
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);

        this.addEditPartner = this.addEditPartner.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        const {id} = this.props;

        if (isEditing) {
            await this.props.GetPartnerById(id)
            const translationsData = {};
            const {partnerById} = this.props;
            if (partnerById) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = partnerById?.translations.find(item => item.language === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                mediaMain: partnerById?.mediaMain,
                icon: partnerById?.icon,
                link: partnerById?.link,
                isHidden: partnerById?.isHidden,

            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia(type, mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set(type, mediaArray[0]?.id)
                : this.updatedDataMap.delete(type);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: mediaArray[0],
            },
            errors: {
                ...errors,
                [type]: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing, mediaModalType} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set(mediaModalType, null) :
                this.updatedDataMap.delete(mediaModalType);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [mediaModalType]: null
            }
        })
    }


    toggleMediaModalType(type = '') {
        this.setState({
            mediaModalType: type
        })
    }

    async addEditPartner() {
        await this.setState({
            requestLoading: true
        });
        const {translations, mediaMain, icon, link, isHidden} = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (!icon) {
            errors.icon = true;
            result = false;
        }
        if (!link) {
            errors.link = true;
            result = false;
        }

        if (result) {

            if (!this.state.isEditing) {
                const reqData = {
                    mediaMain: mediaMain.id,
                    icon: icon.id,
                    link: link,
                    isHidden: isHidden,
                    translations: getTranslationData(translations)

                };

                this.props.CreatePartner(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const reqData = {
                    mediaMain: mediaMain.id,
                    icon: icon.id,
                    link: link,
                    isHidden: isHidden,
                    translations: getTranslationData(translations)
                };
                this.props.UpdatePartnersById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }

    render() {
        const {languageTab, fieldsData, errors, errorsTabs, mediaModalType, requestLoading, isEditing} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};


        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Գործընկեր' : 'Փոփոխել Գործընկերը'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար 1<span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModalType.bind(this, 'mediaMain')}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className="top-side">
                    <label>Նկար 2<span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.icon ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModalType.bind(this, 'icon')}
                    />
                </div>
                {fieldsData?.icon && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.icon}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <InputGroup inputType="input"
                            type="text"
                            label="Հղում"
                            placeholder="Հղում"
                            maxLength={100}
                            name="link"
                            required={true}
                            initValue={this.currentData?.link}
                            value={fieldsData?.link}
                            error={errors['link']}
                            onChange={this.getInputValues}/>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            required={true}
                            onChange={this.getTranslationValues}/>
                <div className="editor-wrapper">
                    <label>Նկարագրություն</label>
                    <Editor value={currentData?.description || ''}
                            name={'description'}
                            initValue={initData?.description}
                            error={errors['description' + languageTab]}
                            onChange={this.getTranslationValues}/>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditPartner}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalType}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia.bind(this, mediaModalType)}
                closeModal={this.toggleMediaModalType.bind(this)}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreatePartner,
    UpdatePartnersById,
    GetPartnerById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPartner)
