import React from "react";
import { history } from "../../configs/history";
import dayjs from "dayjs";
import { Dropdown, Menu } from "antd";

//import assets
import "../../assets/styles/media/mediaCard.scss";

//Import Images
import { iconEdit, iconRemove } from "../../assets/images";

//import utils
import { LOCKED_MEDIA_FOLDERS } from "../../constants/acceptedTypes";
import { MaterialIcon } from "../utils/Icon";
import { iconFolder, iconMore } from "../../assets/images";


export class MediaFolderCard extends React.PureComponent {
    constructor (props) {
        super(props);
        this.state = {
            visible: false
        }
        this.locked = LOCKED_MEDIA_FOLDERS.includes(props?.item?.id);
    }

    setBtnVisible = () => {
        this.setState({
            visible: !this.state.visible
        });
    };

    render () {
        let { item, withEdit, folderList, draggable, listView, selectedItems } = this.props;
        const { visible } = this.state;
        const size = item?.childrenCount ? item?.childrenCount + " pcs" : "Empty";
        draggable = !this.locked && draggable;
        const menu = (
            <Menu>
                {withEdit &&
                <Menu.Item onClick={() => history.push({
                    pathname: `media/edit/${item.id}`,
                    state: {
                        data: item,
                        folderList
                    }
                })}>
                    <img src={iconEdit} alt=""/> Edit
                </Menu.Item>}
                {this.props.deleteMedia && <Menu.Item onClick={() => this.props.deleteMedia(item.id)}>
                    <img src={iconRemove} alt=""/> Remove
                </Menu.Item>}
            </Menu>
        );
        return listView ? <div
                className={`media-list-card folder ${selectedItems?.find(folder => folder?.id === item?.id) ? 'selected-card' : ''}`}
                draggable={draggable}
                onClick={(e) => selectedItems && this.props.selectCard(e, item)}
                onDragOver={(e) => draggable && e.preventDefault()}
                onDragEnd={() => draggable && this.props.dragFile(null)}
                onDragStart={() => draggable && this.props.dragFile(item)}
                onDrop={(e) => draggable && this.props.onDrop(item)}
                key={item.id}
                onDoubleClick={() => this.props.openFolder(item)}>
                <span className={'image-wrapper'}>
                    <div className={'card-image'}>
                        <img className={'image'} src={iconFolder}
                             alt="folder"/>
                    </div>
                </span>
                <span className={'name'}>{item.name}</span>
                <span className={'extension'}>{size}</span>
                <span className={'extension'}>Folder</span>
                <span className={'date'}>{dayjs(item?.createdAt).format('DD/MM/YYYY HH:mm')}</span>
                {!this.locked && (withEdit || this.props.deleteMedia) &&
                <Dropdown overlay={menu} placement="bottomRight"
                          overlayClassName={"actions-dropdown"}
                          onVisibleChange={this.setBtnVisible}>
                    <span className={`more-btn ${visible ? "visible" : ""}`}>
                        <img src={iconMore}
                             alt="icon"/>
                    </span>
                </Dropdown>}
                {this.locked && <span className={'folder-locked'}>
                    <MaterialIcon icon={'lock'}/>
                </span>}
            </div>
            :
            <div
                className={`media-card folder ${selectedItems?.find(folder => folder?.id === item?.id) ? 'selected-card' : ''}`}
                draggable={draggable}
                onClick={(e) => selectedItems && this.props.selectCard(e, item)}
                onDragOver={(e) => draggable && e.preventDefault()}
                onDragEnd={() => draggable && this.props.dragFile(null)}
                onDragStart={() => draggable && this.props.dragFile(item)}
                onDrop={() => draggable && this.props.onDrop(item)}
                key={item.id}
                onDoubleClick={() => this.props.openFolder(item)}>
                <div className={"media-card-wrapper"}>
                    {!this.locked && (withEdit || this.props.deleteMedia) &&
                    <Dropdown overlay={menu}
                              placement="bottomRight"
                              overlayClassName={"actions-dropdown"}
                              onVisibleChange={this.setBtnVisible}>
                        <div className={`more-btn ${visible ? "visible" : ""}`}>
                            <img src={iconMore}
                                 alt="icon"/>
                        </div>
                    </Dropdown>}
                    {this.locked && <div className={'folder-locked'}>
                        <MaterialIcon icon={'lock'}/>
                    </div>}
                    <div className={'image-wrapper'}>
                        <div className={'card-image'}>
                            <img className={'image'} src={iconFolder}
                                 alt="folder"/>
                        </div>
                    </div>
                    <div className={'card-description'}>
                        <span className={'extension'}>Folder</span>
                        <span className={'size'}>{size}</span>
                        <span className={'name'}>{item.name}</span>
                    </div>
                </div>
            </div>
    }
}


