import {request} from "../api";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";
import {PAGES} from "../../constants/constTypes";
import {GENERAL_CONSTS} from "../constants";

export const GetItems = (key) => {
    const requestData = {
        url: PAGES[key]?.requestUrl,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: GENERAL_CONSTS.GET_ITEMS,
                    payload: {key, data}
                });
            })
    }
};

export const GetItemById = (key, id) => {
    const requestData = {
        url: `${PAGES[key]?.requestUrl}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: GENERAL_CONSTS.GET_ITEM_BY_ID,
                    payload: {key, data}
                });
            })
    }
};

export const DeleteItemById = (key, id) => {
    const requestData = {
        url: `${PAGES[key]?.requestUrl}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: GENERAL_CONSTS.DELETE_ITEM,
                    payload: {key, id}
                });
            })
    }
};

export const CreateItem = (key, data) => {
    const requestData = {
        url: PAGES[key]?.requestUrl,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: GENERAL_CONSTS.CREATE_ITEM,
                    payload: {key, data: res.data}
                });
                notificationSuccess({
                    description: "Գործողությունը հաջողությամբ կատարվեց!"
                })
                history.push(`/${key}`)
            })
    }
};

export const UpdateItemById = (key, id, data) => {
    const requestData = {
        url: `${PAGES[key]?.requestUrl}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: GENERAL_CONSTS.UPDATE_ITEM,
                    payload: {key, data: res.data}
                });
                notificationSuccess({
                    description: "Գործողությունը հաջողությամբ կատարվեց!"
                })
                history.push(`/${key}`)
            })
    }
};

export const UpdateItemsPositions = (key, items) => {
    const requestData = {
        url: `${PAGES[key]?.requestUrl}`,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Գործողությունը հաջողությամբ կատարվեց!"
                })
            })
    }
};
export const SetRoutes = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.SET_ROUTES,
            payload: data
        });

    }
};
