// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetItems,
    DeleteItemById,
    UpdateItemsPositions
} from "../../redux/actions";
import {PAGES} from "../../constants/constTypes";


class Distribution extends Component {
    constructor(props) {
        super(props);
        this.key = PAGES.distribution.key;
        this.deleteItem = deleteItem.bind(this, props.DeleteItemById.bind(null, this.key), '');
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount() {
        this.props.GetItems(this.key);
    }

    getNestableItems(list, parent = null) {
        const items = [];
        const {mainLanguage} = this.props;
        !!list?.length && list?.forEach((item, index) => {
            const {link = ' - '} = item

            items.push({
                id: item.id,
                index: index,
                title: link,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.mediaMain.path,
                link: {
                    pathname: `/${this.key}/edit/${item.id}`,
                },
                deleteCb: () => this.deleteItem(item.id),
                children: []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedProjectId = item.id;
        const itemsList = this.props[this.key+"List"];
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~itemsList.findIndex(m => m.id === movedProjectId) &&
            ~positionedItems.findIndex(m => m.id === movedProjectId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateItemsPositions(this.key, positionedItems);
            this.props.GetItems(this.key);
        }
    }

    render() {
        const itemsList = this.props[this.key+"List"];
        let items = this.getNestableItems(itemsList);
        return <PageWrapper pageTitle={'Մատակարարումներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={`/${this.key}/add`}/>
            {items && items.length !== 0 &&
            <Nestable
                items={items}
                maxDepth={1}
                onChange={this.onPositionChange}
                renderItem={NestableItem}
            />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetItems,
    DeleteItemById,
    UpdateItemsPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Distribution);
