import React, {useEffect, useState} from "react";
import { defaultEmptyLogo } from "../../assets/images";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";

export function Image(props) {
    const {path, className = '', alt = ''} = props;
    useEffect(()=>{
        if(!path){
            // props.className = ''
        }
    },[])
    const [brokenImage, setBrokenImage] = useState(false);

    const imageUrl = brokenImage ? defaultEmptyLogo : resizeImage(generateImageMediaUrl(path));

    return <img src={imageUrl}
                className={className}
                alt={alt}
                onError={() => {
                    setBrokenImage(true)
                }}/>
}
