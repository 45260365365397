// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from '../configs/history';
// import ReCAPTCHA from "react-google-recaptcha";

// Import components
import { LinkButton } from "../components/buttons/buttons";
import { EyeInvisibleOutlined, EyeOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';

// Import utils
import { mapStateToProps } from "../redux/mapStateToProps";
import { LoginAdmin, LogOut } from "../redux/actions";


// Import styles
import "../assets/styles/containerStyles/loginPage.scss";
// import { reCAPTCHA_API_KEY } from "../configs/api-keys";

class Login extends Component {
    constructor (props) {
        super(props);

        this.state = {
            creds: {
                email: "",
                password: "",
                grantType: "password"
            },
            errors: {
                email: false,
                password: false,
                recaptcha: false,
            },
            passwordVisible: false
        };
        this.recaptchaRef = React.createRef();
        this.getInputValues = this.getInputValues.bind(this);
        this.validateInputs = this.validateInputs.bind(this);
        this.changePassVisibility = this.changePassVisibility.bind(this);
        this.logUserIn = this.logUserIn.bind(this);
        this.resetAnimation = this.resetAnimation.bind(this);
    }

    componentDidMount () {
        this.props.isLoggedIn
            ? history.push('/dashboard')
            : this.props.LogOut();
    }

    getInputValues (e) {
        const { name, value } = e.target;

        this.setState({
            creds: {
                ...this.state.creds,
                [name]: value
            }
        })
    }

    changePassVisibility () {
        this.setState({
            passwordVisible: !this.state.passwordVisible
        })
    }

    resetAnimation () {
        this.setState({
            errors: {
                email: false,
                password: false
            }
        })
    }

    async logUserIn () {
        // const recaptchaValue = this.recaptchaRef.current.getValue();
        // const getWidgetId = this.recaptchaRef.current.getWidgetId();
        // console.log('recaptchaValue', recaptchaValue);
        // console.log('getWidgetId', getWidgetId);
        if (this.validateInputs()) {
            const reqData={
                ...this.state.creds,
                // recaptchaValue: recaptchaValue,
            }
            this.props.LoginAdmin(reqData).catch(() => {
                // this.recaptchaRef.current.reset();
            })
        }
    }

    validateInputs () {
        const { email, password } = this.state.creds;
        const errors = { email: false, password: false, recaptcha: false };
        // const recaptchaValue = this.recaptchaRef?.current?.getValue();

        let isValidated = true;

        if (email === "") {
            errors.email = true;
            isValidated = false;
        }

        // if (!recaptchaValue) {
        //     errors.recaptcha = true;
        //     isValidated = false;
        // }

        if (password === "") {
            errors.password = true;
            isValidated = false;
        }

        this.setState({
            errors
        });
        return isValidated;
    }

    onChange (value) {
        console.log("Captcha value: onChange", value);
    }

    // onExpired (value) {
    //     console.log("Captcha value: onExpired", value);
    // }
    //
    // onErrored (value) {
    //     console.log("Captcha value: onErrored", value);
    // }

    render () {
        const { requestLoading } = this.props;
        // console.log(requestLoading);
        const { creds, errors, passwordVisible } = this.state;
        return <div className="login-container">
            <div className="login-left-side">
                <div className="logo-title-container">
                    <h3 className="text-upper">Welcome To</h3>
                    <h1 className="page-title">Hybrid Production CMS</h1>
                    <div className="animated-fadeIn">
                        <p className="text-info">Log In to access your page</p>
                    </div>
                </div>
            </div>
            <div className="login-right-side">
                <div className="animated-fadeInRightBig">
                    <h2 className="text-upper">Log In to Hybrid Production CMS</h2>
                    <div className="form-group">
                        <div className={`input-group ${errors.email ? "invalid" : ""}`}>
                            <input type="email"
                                   value={creds.email}
                                   name="email"
                                   placeholder="Username"
                                   onChange={this.getInputValues}
                                   onAnimationEnd={this.resetAnimation}
                                   onKeyDown={(e) => {
                                       if (e.keyCode === 13) {
                                           this.logUserIn()
                                       }
                                   }}/>
                            <UserOutlined/>
                        </div>
                        <div className={`input-group ${errors.password ? "invalid" : ""}`}>
                            <input type={`${passwordVisible ? "text" : "password"}`}
                                   value={creds.password}
                                   name="password"
                                   placeholder="Password"
                                   onChange={this.getInputValues}
                                   onAnimationEnd={this.resetAnimation}
                                   onKeyDown={(e) => {
                                       if (e.keyCode === 13) {
                                           this.logUserIn()
                                       }
                                   }}/>
                            <LockOutlined/>
                            <span className="visibility-icon"
                                  onClick={this.changePassVisibility}>
                                {passwordVisible ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                            </span>
                        </div>
                        <div className="submiting-button" onAnimationEnd={this.resetAnimation}>
                            <LinkButton cb={this.logUserIn}
                                        loading={requestLoading}
                                        title="Log In"/>
                        </div>
                    </div>
                    {/*<div className={`recaptcha ${errors.recaptcha ? 'invalid' : ""}`}>*/}
                    {/*    <ReCAPTCHA*/}
                    {/*        ref={this.recaptchaRef}*/}
                    {/*        sitekey={reCAPTCHA_API_KEY}*/}
                    {/*        onChange={this.onChange}*/}
                    {/*        // onErrored={this.onErrored}*/}
                    {/*        // onExpired={this.onExpired}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    }
}

const mapDispatchToProps = {
    LoginAdmin,
    LogOut
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
