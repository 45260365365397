import React from "react";

import "../assets/styles/containerStyles/footer.scss";
import { SolidyLogo } from "../assets/images";

export function Footer(props) {


    return <div className={`implementation ${props.leftSideMenuOpen ? "show" : ""}`}>
        <div className={'inner'}>
            <span>Իրագործումը՝ </span>
            <a href="https://solidy.am" target={"_blank"} rel="noreferrer">
                <SolidyLogo/>
            </a>
        </div>
    </div>
}
