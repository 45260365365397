// Import packages
import React from "react";
import swal from "sweetalert";

// Import components
import {CustomDropdown} from "../uiElements/CustomDropdown"
import { AppLogoSmall } from "../../assets/images"

// Import styles
import "../../assets/styles/headerStyles/right-menu.scss";

//import utils

export function RightMenu(props) {
    const logUserOut = () => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք դուրս գալ?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                props.LogOut();
            }
        });
    };


    return <div className="right-menu">
        <CustomDropdown className={"menu-wrapper"}
                        inner={<div className="inner-img">
                            <img src={AppLogoSmall} alt="account"/>
                        </div>}
                        content={<>
                            <div className="menu-item" onClick={() => logUserOut()} key={'log-out'}>Դուրս գալ</div>
                        </>}/>
    </div>
}
