// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {MaterialIcon} from "../utils/Icon";

// Import Styles
import '../../assets/styles/components/page-header.scss';

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";

// Import components
import {LinkButton} from "../buttons/buttons";
import {InputGroup} from "../uiElements/inputGroup";
import {IconSearch } from "../../assets/images";


class PageHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                start: '',
                end: '',
                network: undefined,
                station: undefined,
                address: undefined,
                type: undefined,
            },
            filtersWrapperOpen: false
        }
        this.handleChangeData = this.handleChangeData.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.toggleFilters = this.toggleFilters.bind(this);
    }

    handleChangeData(field, value) {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                [field]: value
            }
        })
    }

    changeFilter({name, value}) {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                [name]: value
            }
        });
    }

    toggleFilters() {
        const {filtersWrapperOpen} = this.state
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
            },
            filtersWrapperOpen: !filtersWrapperOpen
        })
    }

    render() {
        const {
            linkTitle, addingLink, rightBottomProps,
            search, searchValue, filters,
        } = this.props;
        const {filtersWrapperOpen} = this.state
        return <div className="content-header">
            <div className={"down-part"}>
                <div className={"down-part-left"}>
                    {search && <div className="search_box">
                        <button type="button" className=" search_btn" onClick={this.props.searchItems}>
                            <IconSearch title={''}/>
                        </button>
                        <InputGroup inputType="input"
                                    type="text"
                                    placeholder="Որոնել"
                                    name="text"
                                    maxLength={256}
                                    onKeyDown={(e) => e && e.key === 'Enter' && this.props?.searchItems?.()}
                                    value={searchValue || ''}
                                    onChange={({value}) => this.props?.getSearchValue?.(value)}/>
                    </div>}
                    {addingLink && <LinkButton title={linkTitle}
                                               link={addingLink}
                                               className={"header-btn"}/>}

                </div>
                {rightBottomProps &&
                <span className={"right-bottom-part"}>{rightBottomProps}</span>}
                {filters && <LinkButton title={<>{filtersWrapperOpen && <MaterialIcon icon="clear"/>}  Ֆիլտրել</>}
                                        cb={this.toggleFilters}
                                        className={`header-btn filter-btn ${filtersWrapperOpen ? "open-filters-btn" : ""}`}/>}
            </div>
            <div className={`filters-wrapper ${filtersWrapperOpen ? "open" : ""}`}>{filtersWrapperOpen && filters}</div>
        </div>
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)
