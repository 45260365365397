import {AUTH_CONSTS, GENERAL_CONSTS,} from "../constants";
import {PAGES} from "../../constants/constTypes";

export const initialState = {
    routesList: [],
};

Object.keys(PAGES).map(key=>{
    initialState[key+'List']=[];
    initialState[key+'ById']=null;
})

export default (state = initialState, action) => {
    switch (action.type) {
        case GENERAL_CONSTS.GET_ITEMS:
            return {
                ...state,
                [action.payload.key+'List']: action.payload.data || [],
            };
        case GENERAL_CONSTS.GET_ITEM_BY_ID:
            return {
                ...state,
                [action.payload.key+'ById']: action.payload.data || [],
            };
        case GENERAL_CONSTS.CREATE_ITEM:
            return {
                ...state,
                [action.payload.key+'List']: [action.payload.data].concat(state[action.payload.key+'List'])
            };
        case GENERAL_CONSTS.DELETE_ITEM:
            return {
                ...state,
                [action.payload.key+'List']: state[action.payload.key+'List'].filter(item => item.id !== action.payload.id)
            };



        case GENERAL_CONSTS.SET_ROUTES:
            return {
                ...state,
                routesList: action.payload,
            };
        case AUTH_CONSTS.LOG_OUT:
            return {
                ...state,
                routesList: []
            }
        default:
            return state;
    }
}