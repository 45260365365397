// Import packages
import React from "react";
import {Route, Switch, Router, Redirect} from "react-router-dom";

// Import utils
import {history} from "../configs/history";
import {ProtectedRoute} from "./protectedRoute";

// Import pages
import AdminLayout from '../containers/AdminLayout';
import Login from '../containers/Login';

import Home from "../containers/Home";

import Contact from '../containers/Contact';

import Media from "../containers/media/Media";
import EditFile from "../containers/media/EditFile";

import Languages from "../containers/languages/Languages";
import EditLanguage from "../containers/languages/EditLanguage";

import Partner from "../containers/partners/Partner";
import AddEditPartner from "../containers/partners/AddEditPartner";

import StaticTexts from "../containers/staticTexts/StaticTexts";

import About from "../containers/about/About";
import AddEditAbout from "../containers/about/AddEditAbout";

import Service from "../containers/services/Service";
import AddEditService from "../containers/services/AddEditService";

import Program from "../containers/program/Program";
import AddEditProgram from "../containers/program/AddEditProgram";

import Distribution from "../containers/distribution/Distribution";
import AddEditDistribution from "../containers/distribution/AddEditDistribution";

import News from "../containers/news/News";
import AddEditNews from "../containers/news/AddEditNews";

import NewsBlocks from "../containers/news-blocks/NewsBlocks";
import AddEditNewsBlocks from "../containers/news-blocks/AddEditNewsBlock";

import AddEditModerator from "../containers/moderators/AddEditModerator";
import Moderators from "../containers/moderators/Moderators";

import NotFound from '../containers/NotFound';
// import Subscribers from "../containers/subscribers/Subscribers";

export default () => {

    return <Router history={history}>
        <Switch>
            <Redirect exact from="/" to="/login"/>
            <Route exact path="/login" component={Login}/>
            <AdminLayout>
                <Switch>
                    <Route exact path="/dashboard" component={Home}/>

                    <ProtectedRoute exact path="/moderators" component={Moderators}/>
                    <ProtectedRoute exact path="/moderators/add" component={AddEditModerator}/>
                    <ProtectedRoute exact path="/moderators/edit/:id" component={AddEditModerator}/>

                    <ProtectedRoute exact path="/media" component={Media}/>
                    <ProtectedRoute exact path="/media/edit/:id" component={EditFile}/>

                    <ProtectedRoute exact path="/distribution" component={Distribution}/>
                    <ProtectedRoute exact path="/distribution/add" component={AddEditDistribution}/>
                    <ProtectedRoute exact path="/distribution/edit/:id" component={AddEditDistribution}/>

                    <ProtectedRoute exact path="/news" component={News}/>
                    <ProtectedRoute exact path="/news/add" component={AddEditNews}/>
                    <ProtectedRoute exact path="/news/edit/:id" component={AddEditNews}/>

                    <ProtectedRoute exact path="/nblock" component={NewsBlocks}/>
                    <ProtectedRoute exact path="/nblock/add" component={AddEditNewsBlocks}/>
                    <ProtectedRoute exact path="/nblock/edit/:id" component={AddEditNewsBlocks}/>

                    <ProtectedRoute exact path="/partners" component={Partner}/>
                    <ProtectedRoute exact path="/partners/add" component={AddEditPartner}/>
                    <ProtectedRoute exact path="/partners/edit/:id" component={AddEditPartner}/>

                    <ProtectedRoute exact path="/program" component={Program}/>
                    <ProtectedRoute exact path="/program/add" component={AddEditProgram}/>
                    <ProtectedRoute exact path="/program/edit/:id" component={AddEditProgram}/>

{/*
                    <ProtectedRoute exact path="/subscribers" component={Subscribers}/>
*/}

                    <ProtectedRoute exact path="/about" component={About}/>
                    <ProtectedRoute exact path="/about/add" component={AddEditAbout}/>
                    <ProtectedRoute exact path="/about/edit/:id" component={AddEditAbout}/>

                    <ProtectedRoute exact path="/service" component={Service}/>
                    <ProtectedRoute exact path="/service/add" component={AddEditService}/>
                    <ProtectedRoute exact path="/service/edit/:id" component={AddEditService}/>

                    <ProtectedRoute exact path="/languages" component={Languages}/>
                    <ProtectedRoute exact path="/languages/edit/:id" component={EditLanguage}/>

                    <ProtectedRoute exact path="/contact" component={Contact}/>
                    <ProtectedRoute exact path="/staticTexts" component={StaticTexts}/>

                    <Route exact path="/404" component={NotFound}/>
                    <Route component={NotFound}/>
                </Switch>
            </AdminLayout>
        </Switch>
    </Router>
}
