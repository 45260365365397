 export const StaticTextFields = {
    header: {
        pageName: 'Header',
        list: [
            {
                label: "Header - services",
                key: "header_item_services"
            },
            {
                label: "Header - about",
                key: "header_item_about"
            },
            {
                label: "Header - news",
                key: "header_item_news"
            },
            {
                label: "Header - contact",
                key: "header_item_contact"
            },
            {
                label: "Header - text",
                key: "header_item"
            },
        ]
    },
    homepage: {
        pageName: 'Homepage',
        list: [
            {
                label: "Homepage - about",
                key: "homepage_about"
            },
            {
                label: "Homepage - info btn",
                key: "homepage_more"
            },
            {
                label: "Homepage - main subtitle",
                key: "homepage_main_subtitle"
            },
            {
                label: "Homepage - main title",
                key: "homepage_main_title"
            },
            {
                label: "Homepage - main description",
                key: "homepage_main_description"
            },
            {
                label: "Homepage - main link",
                key: "homepage_main_link"
            },
            {
                label: "Homepage - partner subtitle",
                key: "homepage_partner_subtitle"
            },
            {
                label: "Homepage - partner title",
                key: "homepage_partner_title"
            },
            {
                label: "Homepage - partner description",
                key: "homepage_partner_description"
            },
            {
                label: "Homepage - partner more",
                key: "homepage_partner_more"
            },
            {
                label: "Homepage - programs title",
                key: "homepage_programs_title"
            },
            {
                label: "Homepage - programs contact",
                key: "homepage_programs_contact"
            },
            {
                label: "Homepage - distribution title",
                key: "homepage_distribution_title"
            },
            {
                label: "Homepage - distribution description",
                key: "homepage_distribution_description"
            },
            {
                label: "Homepage - distribution block title",
                key: "homepage_distribution_block_title"
            },
            {
                label: "Homepage - service subtitle",
                key: "homepage_service_subtitle"
            },
            {
                label: "Homepage - service title",
                key: "homepage_service_title"
            },
            {
                label: "Homepage - service link",
                key: "homepage_service_link"
            },
        ]
    },
    services:{
        pageName:"Services",
        list:[
            {
                label:"Service -Page subtitle",
                key:"service_page_subtitle"
            },
            {
                label:"Service -Page title",
                key:"service_page_title"
            },
            {
                label:"Service -Page description",
                key:"service_page_description"
            },
            {
                label:"Service -Page blocks title",
                key:"service_page_blocks_title"
            },
            {
                label:"Service -Page blocks description",
                key:"service_page_blocks_description"
            },
            {
                label:"Service -Page blocks connect",
                key:"service_page_blocks_connect"
            },
            {
                label:"Service -Page blocks download",
                key:"service_page_blocks_download"
            },
            {
                label:"Service -Page distribution info",
                key:"service_page_distribution_info"
            },
        ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - info title",
                key: "footer_info_title"
            },
            {
                label: "Footer - section home",
                key: "footer_item_header"
            },
            {
                label: "Footer - section services",
                key: "footer_item_services"
            },
            {
                label: "Footer - section about",
                key: "footer_item_about"
            },
            {
                label: "Footer - section news",
                key: "footer_item_news"
            },
            {
                label: "Footer - section contact",
                key: "footer_item_contact"
            },
            {
                label: "Footer - service title",
                key: "footer_service_title"
            },
            {
                label: "Footer - first service",
                key: "footer_service_first"
            },
            {
                label: "Footer - second service",
                key: "footer_service_second"
            },
            {
                label: "Footer - copyright",
                key: "footer_cooperation"
            },
            {
                label: "Footer - section presentation file text",
                key: "footer_section_presentationFile_text"
            },
        ]
    },
    news: {
        pageName: 'News',
        list: [
            {
                label: "News - Page subtitle",
                key: "news_page_subtitle"
            },
            {
                label: "News - Page title",
                key: "news_page_title"
            },
            {
                label: "News - Page description",
                key: "news_page_description"
            },
            {
                label: "News - Page link text",
                key: "news_page_link_text"
            },
            {
                label: "News - Page pdf text",
                key: "news_page_pdf_text"
            },
            {
                label: "News - Page blocks subtitle",
                key: "news_page_block_subtitle"
            },
            {
                label: "News - Page blocks title",
                key: "news_page_block_title"
            },
            {
                label: "News - Page blocks description",
                key: "news_page_block_description"
            },
        ]
    },
    about: {
        pageName: 'About',
        list: [
            {
                label: "About - page subtitle",
                key: "about_page_subtitle"
            },
            {
                label: "About - page title",
                key: "about_page_title"
            },
            {
                label: "About - page description",
                key: "about_page_description"
            },
        ]
    },
    contacts: {
        pageName: 'Contacts',
        list: [
            {
                label: "Contacts - page subtitle",
                key: "contact_page_subtitle"
            },
            {
                label: "Contacts - page title",
                key: "contact_page_title"
            },
            {
                label: "Contacts - page description",
                key: "contact_page_description"
            },
            {
                label: "Contacts - page email title",
                key: "contact_page_email_title"
            },
            {
                label: "Contacts - email description",
                key: "contact_page_email_description"
            },
            {
                label: "Contacts - address title",
                key: "contact_page_address_title"
            },
            {
                label: "Contacts - address map",
                key: "contact_page_address_map"
            },
            {
                label: "Contacts - phone title",
                key: "contact_page_phone_title"
            },
            {
                label: "Contacts - work time",
                key: "contact_page_work_time"
            },
            {
                label: "Contacts - inputs subtitle",
                key: "contact_page_inputs_subtitle"
            },
            {
                label: "Contacts - inputs title",
                key: "contact_page_inputs_title"
            },
            {
                label: "Contacts - inputs description",
                key: "contact_page_inputs_description"
            },
            {
                label: "Contacts - Page placeholder name",
                key: "contacts_page_placeholder_name"
            },
            {
                label: "Contacts - Page label name",
                key: "contacts_page_label_name"
            },
            {
                label: "Contacts - Page placeholder lastName",
                key: "contacts_page_placeholder_lastName"
            },
            {
                label: "Contacts - Page label lastName",
                key: "contacts_page_label_lastName"
            },
            {
                label: "Contacts - Page placeholder email",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "Contacts - Page label email",
                key: "contacts_page_label_email"
            },
            {
                label: "Contacts - Page placeholder phoneNumber",
                key: "contacts_page_placeholder_phoneNumber"
            },
            {
                label: "Contacts - Page label phoneNumber",
                key: "contacts_page_label_phoneNumber"
            },
            {
                label: "Contacts - Page placeholder message",
                key: "contacts_page_placeholder_message"
            },
            {
                label: "Contacts - Page label message",
                key: "contacts_page_label_message"
            },
            {
                label: "Contacts - Page btn send",
                key: "contacts_page_btn_send"
            },
        ]
    },
}