import swal from "sweetalert";

export function changeStateField(field, value) {
    this.setState({
        [field]: value,
    })
}

export function changeStateInputValue({value, name}) {
    this.setState({
        [name]: value,
    })
}

export function toggleStateField(field) {
    this.setState({
        [field]: !this.state[field],
    })
}

export function initTranslations (fieldsList, activeLanguages) {
    const translationsData = {};
    activeLanguages && activeLanguages.forEach(lg => {
        translationsData[lg.code] = {};

        fieldsList.forEach(field => {
            if (field === 'options') {
                translationsData[lg.code][field] = [];
            } else {
                translationsData[lg.code][field] = '';            }
        })
    });
    return translationsData;
}

export function getInputValues ({ name, value, haveChanges }) {
    // console.log(name, value, haveChanges)
    const { isEditing, fieldsData } = this.state;
    if ((name === 'latitude') && value && (value < -90 || value > 90)) {
        return;
    }
    if ((name === 'longitude') && value && (value < -180 || value > 180)) {
        return;
    }
    if (name === 'slug') {
        value = value.replace(/\\|\/| /gi, '-');
    }
    if (isEditing && name !== 'repeatPassword') {
        let mapValue = value
        if (name === 'parent' || name === 'measurementUnit' || name === 'oldPrice' || name === 'badges'
            || name === 'quantity' || name === 'bonusPoints' || name === 'resourceId') {
            mapValue = value || null;
        }
        haveChanges
            ? this.updatedDataMap.set(name, mapValue)
            : this.updatedDataMap.delete(name);
    }
    this.setState({
        fieldsData: {
            ...fieldsData,
            [name]: value,
        }
    })
    // console.log("updatedDataMap", this.updatedDataMap)
}

export function getTranslatableInputValues ({ name, value, haveChanges }) {
    //console.log(name, value, haveChanges)
    const { fieldsData, isEditing, languageTab } = this.state;
    const { translations } = fieldsData;
    if (name === 'slug') {
        value = value.replace(/\\|\/| /gi, '-');
    }
    if (isEditing) {
        haveChanges
            ? this.updatedDataMap.set(name + '_' + languageTab, value)
            : this.updatedDataMap.delete(name + '_' + languageTab);

    }
    this.setState({
        fieldsData: {
            ...fieldsData,
            translations: {
                ...translations,
                [languageTab]: {
                    ...translations[languageTab],
                    [name]: value
                }
            }

        }
    })
    //console.log("updatedDataMap", this.updatedDataMap)
}

export function getTranslationData (translations) {
    const TR_data = [];
    Object.keys(translations).forEach(key => {
        const reqTrItem = {
            language: key,
        };
        let translationIsEmpty = true;
        const trItem = translations[key];
        Object.keys(trItem).forEach(field => {
            if (trItem[field]) {
                if(Array.isArray(trItem[field])){
                    const trFieldList = []
                    trItem[field].forEach(i=>{
                        i && trFieldList.push(i)
                    })
                    if(trFieldList.length){
                        reqTrItem[field] = trFieldList;
                        translationIsEmpty = false;
                    }
                }else {
                    if(field === 'presentationFile'){
                        reqTrItem[field] = trItem[field]?.id;
                        translationIsEmpty = false;
                    }else{
                        reqTrItem[field] = trItem[field];
                        translationIsEmpty = false;
                    }
                }
            }
        });
        //if translation is empty not add to list
        !translationIsEmpty && TR_data.push(reqTrItem);
    });
    return TR_data;
}


export function disabledStartDate (startValue, endValue) {

    if (!startValue || !endValue) {
        return false;
    }
    return startValue.valueOf() > endValue.valueOf();
}

export function disabledEndDate (endValue, startValue) {

    if (!endValue || !startValue) {
        return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
}


export const arraysEquals = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
        return false;
    }
    return arr1.length === arr2.length
        && arr1.every(id => arr2.some(_id => _id === id));
};

export function deleteItem(deletingFunction, itemName, id,slug) {
    swal({
        title: "Զգուշացում!",
        text: `Ցանկանում եք ջնջել ${itemName}?`,
        icon: "warning",
        buttons: ["Ոչ", "Այո"]
    }).then(confirm => {
        if (confirm) {
            deletingFunction(id,slug);
        }
    });
}


export function getPostReqData (fieldsData) {
    const reqData = {};

    Object.keys(fieldsData).forEach(field => {
            const dataItem = fieldsData[field];
            if (field === 'translations') {
                reqData.translations = getTranslationData(dataItem)
            } else if (
                (field === 'products' ||
                    field === 'identicalProducts' ||
                    field === 'medias')
                && dataItem.length) {
                reqData[field] = dataItem.map(item => item.id)
            } else if (dataItem && (field === 'mediaMain' ||
                field === 'coverMedia' ||
                field === 'icon' ||
                field === 'video')) {
                reqData[field] = dataItem.id
            } else if ((dataItem && dataItem !== '' && dataItem !== 0
                && !Array.isArray(dataItem)) || (Array.isArray(dataItem)
                && dataItem.length)) {
                reqData[field] = dataItem
            }
        }
    )
    return reqData;
}

export function getPatchReqData (TR_data, whiteFields = null, blackFields = null) {
    const reqData = {};
    this.updatedDataMap.forEach((value, key) => {
        if (this.translationsFields.some(field => key.includes(field))) {
            reqData.translations = TR_data;
        } else if ((!whiteFields && !blackFields)
            || (whiteFields && whiteFields?.some(field => field === key))
            || (blackFields && blackFields?.every(field => field !== key))) {
            reqData[key] = value;
        }
    })
    return reqData
}
