import {request, _urlAbout} from "../api";
import {ABOUT_CONSTS} from "../constants";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";

export const GetAboutData = () => {
    const requestData = {
        url: _urlAbout,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: ABOUT_CONSTS.SET_ABOUT_DATA,
                    payload: data
                });
            })
    }
};


export const GetAboutById = (id) => {
    const requestData = {
        url: `${_urlAbout}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
               return data;
            })
    }
};

export const UpdateAboutById = (id, data) => {
    const requestData = {
        url: `${_urlAbout}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                history.push('/about')
            })
            .catch(() => {
            })
    }
};
