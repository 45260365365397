// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/containerStyles/page-add-edit.scss';
import '../../assets/styles/components/medias-list-wrapper.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData, getTranslatableInputValues, getTranslationData, initTranslations,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateItem, GetItemById, UpdateItemById} from "../../redux/actions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {validateTranslations} from "../../utils/validateTranslations";
import {Editor} from "../../components/uiElements/Editor";
import {Switch} from "antd";
import {MEDIA_MODAL_TYPES, PAGES} from "../../constants/constTypes";
import {MaterialIcon} from "../../components/utils/Icon";
import Nestable from "react-nestable/dist/Nestable";

class AddEditNewsBlock extends Component {
    constructor(props) {
        super(props);
        this.key = PAGES.nblock.key;

        this.translationsFields = [
            'title',
            'description`',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                pdfs: [],
                isHidden: false,
                options: [],
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props.id,
            languageTab: props?.mainLanguage||'hy',
            requestLoading: false,
            mediaModalType: null,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);

        this.toggleMediaModal = changeStateField.bind(this, 'mediaModalType');

        this.checkMediaListDifferent = this.checkMediaListDifferent.bind(this);
        this.onMediaPositionChange = this.onMediaPositionChange.bind(this);
        this.mediaCardWrapper = this.mediaCardWrapper.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);



        this.addOptionItem = this.addOptionItem.bind(this);
        this.deleteOption = this.deleteOption.bind(this);
        this.onOptionPositionChange = this.onOptionPositionChange.bind(this);

        this.addEditItem = this.addEditItem.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        const {id} = this.props;

        if (isEditing) {
            await this.props.GetItemById(this.key, id)
            const translationsData = {};
            const dataById = this.props[this.key+"ById"];

            if (dataById) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = dataById?.translations.find(item => item.language === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                pdfs: dataById?.pdfs || [],
                isHidden: dataById?.isHidden,
                options: Array.isArray(dataById.links) ? dataById.links : [],

            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    //medias
    getMedia (mediaArray, mediaType) {
        const { fieldsData, errors, isEditing, mediaModalType } = this.state;
        if (isEditing) {
            if (mediaType === MEDIA_MODAL_TYPES.MEDIA_MAIN) {
                this.currentData?.[mediaModalType]?.id !== mediaArray[0]?.id
                    ? this.updatedDataMap.set(mediaModalType, mediaArray[0]?.id)
                    : this.updatedDataMap.delete(mediaModalType);
            } else {
                this.checkMediaListDifferent(mediaArray)
            }

        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [mediaModalType]: mediaType === MEDIA_MODAL_TYPES.MEDIA_MAIN
                    ? mediaArray[0] : mediaArray,
            },
            errors: {
                ...errors,
                [mediaModalType]: false
            }
        })
    }

    deleteMedia (type, id) {
        const { fieldsData, isEditing } = this.state;
        let pdfs = fieldsData.pdfs.filter(m => m.id !== id);

        if (isEditing) {
            if (type === 'pdfs') {
                this.checkMediaListDifferent(pdfs)
            } else {
                this.currentData?.[type] ?
                    this.updatedDataMap.set(type, null) :
                    this.updatedDataMap.delete(type);
            }

        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: type === 'pdfs' ? pdfs : null
            }
        })
    }

    checkMediaListDifferent (currMedias) {
        let result = false;
        const initMedias = this.currentData.pdfs;
        if (!Array.isArray(currMedias) || (currMedias?.length !== initMedias.length)) {
            result = true;
        } else {
            currMedias.forEach((item, index) => {
                if (initMedias[index]?.id !== item?.id) {
                    result = true;
                }
            })
        }
        result ? this.updatedDataMap.set('pdfs', currMedias?.map(m => m.id))
            : this.updatedDataMap.delete('pdfs');
    }

    onMediaPositionChange (items) {
        const { fieldsData, isEditing } = this.state;
        const pdfs = items.map(item => item.media);
        if (isEditing) {
            // this.checkMediaListDifferent(medias)
            this.updatedDataMap.set('pdfs', pdfs?.map(m => m.id))
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                pdfs: pdfs
            }
        })
    }

    mediaCardWrapper (data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={this.deleteMedia.bind(this, 'pdfs', data?.item?.media?.id)}/>
    }
d

//options
    addOptionItem = () => {
        const { isEditing, } = this.state
        const options = [
            '', ...this.state.fieldsData.options]
        if (isEditing) {
            this.updatedDataMap.set('options', options)
        }
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                options: options
            }
        })
    }

    deleteOption (index) {
        const { isEditing, fieldsData, errors } = this.state;
        const options = fieldsData.options.filter((_, pos) => pos !== index)
        if (isEditing) {
            this.updatedDataMap.set('options', options)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                options: options
            },
            errors: {
                ...errors,
                options: {}
            }
        })
    }

    onOptionPositionChange = (items) => {
        const { fieldsData, isEditing } = this.state;
        const options = items.map(item => item.option);
        if (isEditing) {
            this.updatedDataMap.set('options', options)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                options
            }
        })
    }

    getOptionValue = (index, value) => {
        const { isEditing } = this.state;

        const options = this.state.fieldsData.options.map((item, pos) => {
            return pos === index ? value : item
        })
        if (isEditing) {
            this.updatedDataMap.set('options', options)
        }
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                options: options
            }
        })
    }

    optionItem = (data) => {
        const { errors } = this.state
        const { index, link } = data.item
        return <div className={'option-item'}>
            <InputGroup inputType="input"
                        type="text"
                        label="Հղում"
                        placeholder="Հղում"
                        name="link"
                        value={link}
                        maxLength={256}
                        error={errors?.options?.[index + 'link']}
                        onChange={({ value }) => this.getOptionValue(index, value, 'link')}/>
            <div className={`delete-btn`} onClick={() => this.deleteOption(index)}>
                <MaterialIcon icon="delete"/>
            </div>
        </div>
    }

    getOptionNestableItems = () => {
        const { fieldsData } = this.state
        const { options } = fieldsData
        const items = [];
        options && !!options.length && options.forEach((link, index) => {
            items.push({
                id: index,
                index: index,
                link: link
            })
        });
        return items;
    }

//add-edit
    async addEditItem() {
        await this.setState({
            requestLoading: true
        });
        const {translations, pdfs, options, isHidden} = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result =validationTr.result;
        const errors = {...validationTr.errors};

        errors.options = {}
        options && options.forEach((link, index) => {
            if (!link) {
                errors.options[index + 'link'] = true;
                result = false;
            }
        })
        if (result) {
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden: isHidden,
                    pdfs: pdfs.length ?pdfs.map(m=>m.id):[],
                    translations: getTranslationData(translations),
                    links:options
                };

                this.props.CreateItem(this.key, reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const reqData = {
                    isHidden: isHidden,
                    pdfs: pdfs.length ?pdfs.map(m=>m.id):[],
                    translations: getTranslationData(translations),
                    links:options

                };
                this.props.UpdateItemById(this.key, this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs

            })
        }
    }

    render() {
        const {languageTab, fieldsData, errors, errorsTabs, mediaModalType, requestLoading, isEditing} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        const mediaItems = [];
        fieldsData.pdfs.forEach(media => {
            mediaItems.push({
                id: media?.id,
                media: media
            })
        });
        // const dataById = this.props[this.key+"ById"];
        let items = this.getOptionNestableItems();
        console.log(errors);
        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}>
            <section className="page-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className={'medias-list-wrapper'}>
                    <div className={'label-and-action'}>
                        <label>ՊԴՖ-ներ</label>
                        <LinkButton title="Ընտրել Մեդիադարանից"
                                    className={`media-select-btn `}
                                    cb={this.toggleMediaModal.bind(this, 'pdfs')}/>
                    </div>
                    <section className={'items-wrapper'}>
                        {mediaItems.length ? <Nestable
                                items={mediaItems}
                                maxDepth={1}
                                onChange={this.onMediaPositionChange}
                                renderItem={this.mediaCardWrapper}
                            /> :
                            <p className={'empty-text'}>ՊԴՖ-ներ չկան</p>}
                    </section>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            required={true}
                            onChange={this.getTranslationValues}/>
                <div className="editor-wrapper">
                    <label>Նկարագրություն</label>
                    <Editor value={currentData?.description || ''}
                            name={'description'}
                            initValue={initData?.description}
                            error={errors['description' + languageTab]}
                            onChange={this.getTranslationValues}/>
                </div>
                <div className={'options-wrapper'}>
                    <label>Հղումներ </label>
                    <div className={'add-row'}>
                        <LinkButton title="Ավելացնել"
                                    className={`media-select-btn`}
                                    cb={this.addOptionItem}/>
                    </div>
                    {items && items.length !== 0 &&
                    <Nestable
                        items={items}
                        maxDepth={1}
                        onChange={this.onOptionPositionChange}
                        renderItem={this.optionItem}
                    />}
                         {!!fieldsData.options.length && fieldsData.options.map(option=>{
                        return <div className={'option-item'}>

                        </div>
                    })}
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditItem}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalType}
                acceptTypes={mediaModalType === "pdfs" ?['pdf']:ACCEPT_IMAGE_TYPES}
                mediaType={mediaModalType === "pdfs" ? MEDIA_MODAL_TYPES.MEDIA_ARRAY : MEDIA_MODAL_TYPES.MEDIA_MAIN}
                appendMedias={mediaModalType === "pdfs" && fieldsData.pdfs}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateItem,
    UpdateItemById,
    GetItemById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditNewsBlock)
