export const ContactAddingList = [
    {
        label: "հեռախոսահամար",
        inputType: "input",
        type: "text",
        // regExp:/^[+\d]\d*$/,
        placeholder: "հեռախոսահամար",
        name: "phoneNumber",
        maxLength: 20
    },
];
