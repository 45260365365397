import {Skeleton} from "antd";
import React from "react";
import '../../assets/styles/components/skelaton-item.scss';

export const SkeletonItem = () => {
    return <div className={'skelton-item'}>
        <Skeleton loading={true}
                  paragraph={{
                      rows: 3,
                      width: "100%"
                  }}
                  active
        />
    </div>
}
