export const ACCESS_PAGES = {
    dashboard: {
        key: 'home',
        name: 'Գլխավոր',
        icon: 'home',
        crud: true,
        isHidden: true,
    },
    moderators: {
        key: 'moderator',
        name: 'Մոդերատորներ',
        icon: 'supervised_user_circle',
        crud: false,
        isHidden: true,
    },
    media: {
        key: 'file',
        name: 'Ֆայլեր',
        icon: 'filter',
        crud: true,
        isHidden: false,
    },
    about: {
        key: 'about',
        name: 'Մեր Մասին',
        icon: 'category',
        crud: true,
        isHidden: false,
    },
    partners: {
        key: 'partner',
        name: 'Գործընկերներ',
        icon: 'group_work',
        crud: true,
        isHidden: false,
    },
    distribution: {
        key: 'distribution',
        name: 'Մատակարարումներ',
        icon: 'grading',
        crud: true,
        isHidden: false,
    },
    news: {
        key: 'news',
        name: 'Նորություններ',
        icon: 'receipt',
        crud: true,
        isHidden: false,
    },
    'nblock': {
        key: 'news-block',
        name: 'Նորության բլոկներ',
        icon: 'group_work',
        crud: true,
        isHidden: false,
    },
    service: {
        key: 'service',
        name: 'Ծառայություններ',
        icon: 'folder_shared',
        crud: true,
        isHidden: false,
    },
    program: {
        key: 'our-program',
        name: 'Ծրագրեր',
        icon: 'amp_stories',
        crud: true,
        isHidden: false,
    },
    languages: {
        key: 'language',
        name: 'Լեզուներ',
        icon: 'language',
        crud: true,
        isHidden: false,
    },
    contact: {
        key: 'contact',
        name: 'Կապ',
        icon: 'contact_phone',
        crud: true,
        isHidden: false,
    },
    staticTexts: {
        key: 'static-texts',
        name: 'Թարգմանություններ',
        icon: 'text_format',
        crud: true,
        isHidden: false,
    },
};
