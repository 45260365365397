// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

// Import styles
import "../../assets/styles/containerStyles/edit-file.scss";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { UpdateFile } from "../../redux/actions";
import { history } from "../../configs/history";
import isURL from "validator/es/lib/isURL";

class EditFile extends Component {
    constructor (props) {
        super(props);
        this.state = {
            fieldsData: {
                name: '',
                url: '',
                path: '',
            },
            errors: {
                name: false,
            },
            isEditing: true,
            requestLoading: false,
            languageTab: props.mainLanguage,
        };
        this.type = null;
        this.currentData = null;
        this.getInputValues = this.getInputValues.bind(this);
        this.updateFile = this.updateFile.bind(this);
    }

    componentDidMount () {
        const { location } = this.props;
        const fileData = location && location.state && location.state.data;
        this.type = fileData && fileData.type;
        if (!this.type) {
            history.push('/media')
        } else {
            this.currentData = fileData ? { ...fileData } : {};
            fileData && this.setState({
                fieldsData: {
                    name: fileData.name,
                    path: fileData.path,
                    url: fileData.url,
                },
            })
        }
    }


    getInputValues ({ name, value }) {
        //console.log(/[^-a-zA-Zա-ֆԱ-Ֆа-яА-Я0-9]*$/.test(value))
        // if (name === 'name' && !(/^[-a-zA-Zա-ֆԱ-Ֆа-яА-Я0-9]*$/.test(value))) {
        //     return;
        //     // value = value.replace(/[^-a-zA-Zա-ֆԱ-Ֆа-яА-Я0-9]*$/gi, '');
        // }

        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                [name]: value
            }
        })
    }

    async updateFile () {
        const { name, url } = this.state.fieldsData;
        if (!name) {
            this.setState({
                errors: {
                    name: true
                }
            });
            return;
        }
        if (url && !isURL(url)) {
            this.setState({
                errors: {
                    url: true
                }
            });
            return;
        }

        let reqData = { name: name };
        // let parent = this.currentData && this.currentData.parent;
        // if (parent) {
        //     reqData.url = url;
        //     reqData.name = name;
        //     reqData.parent = parent
        // }

        this.props.UpdateFile(this.props.id, reqData, this.type).then(() => {
            history.push({
                pathname: '/media'
            })
        }).catch((error) => {
        });

    }

    render () {
        const { requestLoading } = this.props;
        const { fieldsData, errors } = this.state;
        return <PageWrapper pageTitle={`Փոփոխել ${this.type === 'FOLDER' ? ' Թղթապանակը' : ' Ֆայլը'}`}>
            <div className="admin-edit-file">
                <InputGroup inputType="input"
                            type="text"
                            label="Name"
                            name="name"
                            maxLength={50}
                            required={true}
                            error={errors.name}
                            value={fieldsData.name}
                            onChange={this.getInputValues}/>
                {/*
                <InputGroup inputType="input"
                            type="text"
                            label="Հղում"
                            name="url"
                            maxLength={50}
                            required={true}
                            error={errors.url}
                            value={fieldsData.url}
                            onChange={this.getInputValues}/>*/}

                <div className="flex-wrapper-right">
                    <LinkButton cb={this.updateFile}
                                loading={requestLoading}
                                title="Փոփոխել"/>
                </div>
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateFile
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditFile));
