//--------------------------------------- Official-server --------------------------------------------
// const OFFICIAL_URLS = {
//     AUTH_URL: "http://37.60.224.224:3100/api/v1",
//     ADMIN_URL: "http://37.60.224.224:3101/api/v1",
//     MEDIA_URL: "http://37.60.224.224:3103/api/v1",
//     HOST_MEDIA_URL: "http://37.60.224.224:3103",
// }

const OFFICIAL_URLS = {
    AUTH_URL: "https://app.hpd.am/auth/api/v1",
    ADMIN_URL: "https://app.hpd.am/admin/api/v1",
    MEDIA_URL: "https://app.hpd.am/file/api/v1",
    HOST_MEDIA_URL: "https://app.hpd.am/file",
}

//--------------------------------------- 4Steps-server --------------------------------------------
const TESTING_URLS = {
    AUTH_URL: "http://173.212.233.220:3400/api/v1",
    ADMIN_URL: "http://173.212.233.220:3401/api/v1",
    MEDIA_URL: "http://173.212.233.220:3403/api/v1",
    HOST_MEDIA_URL: "http://173.212.233.220:3403",
}
const { AUTH_URL, ADMIN_URL, MEDIA_URL, HOST_MEDIA_URL, } =
       OFFICIAL_URLS;
       // TESTING_URLS;

export const _urlOauth = AUTH_URL;
export const _urlMedia = MEDIA_URL + "/file";
export const _urlLanguages = ADMIN_URL + "/language";
export const _urlContact = ADMIN_URL + "/contact";
export const _urlModerator = ADMIN_URL + "/moderator";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlProduct = ADMIN_URL + "/products";
export const _urlSubscribers = ADMIN_URL + "/subscribers";
export const _urlRequests = ADMIN_URL + "/call-request";
export const _urlOrderRequests = ADMIN_URL + "/order-request";
export const _urlAbout = ADMIN_URL + "/about";
export const _urlServices = ADMIN_URL + "/service";
export const _urlNewsBlock = ADMIN_URL + "/news-block";
export const _urlProgram = ADMIN_URL + "/our-program";
export const _urlPartner = ADMIN_URL + "/partner";
export const _urlSliders = ADMIN_URL + "/slider";
export const _urlNews = ADMIN_URL + "/news";
export const _urlHome = ADMIN_URL + "/home";
export const _urlDistribution = ADMIN_URL + "/distribution";


export const _hostMedia = HOST_MEDIA_URL;
