import axios from "axios";
import { store } from "../store";
import { history } from "../../configs/history";
import { AUTH_CONSTS, UTIL_CONSTS } from "../constants";
import { RefreshToken, TokenValidation } from "../actions";
import swal from "sweetalert";
import { notShowErrorsRequests } from "../../constants/notShowDefaultErrorModalRequests";

export const request = ({ url, method = "GET", data = null, token = false, customHeaders = null, language = true }) => {
    const scope = store.getState() && store.getState().auth && store.getState().auth.scope;

    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "scope": scope
    };
    if (token) {
        headers["Authorization"] = "Bearer " + store.getState().auth.accessToken;
        customHeaders && (customHeaders["Authorization"] = "Bearer " + store.getState().auth.accessToken);
    }

    if (language) {
        headers["Accept-Language"] ="hy";
        customHeaders && (customHeaders["Accept-Language"] ="hy");
    }

    scope && customHeaders && (customHeaders["scope"] = scope);

    const requestData = {
        url: url,
        method: method,
        headers: customHeaders || headers,
    };

    data && (requestData.data = data);

    return axios.request(requestData)
};

// log user out when token expires

axios.interceptors.response.use(response => {
        store.dispatch({
            type: UTIL_CONSTS.END_LOADING
        });
        return response;
    },

    async error => {

        const originalRequest = error.config;
        // console.log('error', error);
        // console.log('originalRequest', originalRequest);
        if (originalRequest.url.slice(-13) === "refresh-token") {
            // if refresh token has been expired
            //console.log('refresh token has been expired');
        } else if (error.response && error.response.status === 401) {
            const accessToken = await RefreshToken();
            // console.log('accessToken', accessToken);
            if (accessToken) {
                originalRequest.headers['Authorization'] = "Bearer " + accessToken;
                return axios(originalRequest);
            } else {
                !originalRequest.url.includes('authenticate') && swal({
                    title: 'Զգուշացում',
                    text: 'Խնդրում ենք կրկին փորձել մուտք գործել',
                    icon: "warning",
                    button: 'Լավ'
                });
                store.dispatch({
                    type: AUTH_CONSTS.LOG_OUT
                });
                history.push("/login");
            }
        }
        if (error.response && error.response.status === 403) {
            TokenValidation()(store.dispatch).then(() => {
                history.push('/products');
            });
        } else {
            const notShowModal = notShowErrorsRequests.some(request => {
                return originalRequest.url.includes(request.url)
                    && originalRequest.method === request.method
            })
            !notShowModal && swal({
                title: 'Չհաջողվեց',
                text: 'Ինչ որ բան այն չէ, Խնդրում ենք  փորձել կրկին',
                icon: "error",
                button: 'Լավ'
            });
        }
        store.dispatch({
            type: UTIL_CONSTS.END_LOADING
        });
        return Promise.reject(error);
    }
);
