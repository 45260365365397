// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'

// Import utils
import {deleteItem} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    DeleteItemById,
    GetItems,
    UpdateItemsPositions,

} from "../../redux/actions";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {NestableItem} from "../../components/cards/nestableItem";
import Nestable from "react-nestable";
import {PAGES} from "../../constants/constTypes";

class NewsBlocks extends Component {
    constructor(props) {
        super(props);
        this.key = PAGES.nblock.key;
        this.deleteItem = deleteItem.bind(this, props.DeleteItemById.bind(null, this.key), '');
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount() {
        this.props.GetItems(this.key);
    }

    getNestableItems(list) {
        const items = [];
        !!list?.length && list?.forEach((item, index) => {
            const {title = ' - '} = item

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: false,
                isHidden: item?.isHidden,
                link: {
                    pathname: `/${this.key}/edit/${item.id}`,
                },
                deleteCb: () => this.deleteItem(item?.id),
                children: []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedProjectId = item.id;
        const itemsList = this.props[this.key + "List"];
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~itemsList.findIndex(m => m.id === movedProjectId) &&
            ~positionedItems.findIndex(m => m.id === movedProjectId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateItemsPositions(this.key, positionedItems);
            this.props.GetItems(this.key);
        }
    }


    render() {
        const itemsList = this.props[this.key + "List"];
        let items = this.getNestableItems(itemsList);
        return <PageWrapper pageTitle={'Նորության բլոկներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={`/${this.key}/add`}/>
            {items && items.length !== 0 &&
            <Nestable
                items={items}
                maxDepth={1}
                onChange={this.onPositionChange}
                renderItem={NestableItem}
            />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetItems,
    DeleteItemById,
    UpdateItemsPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsBlocks)
