import React from "react";
import {Link} from "react-router-dom";

//import assets
import "../../assets/styles/headerStyles/headerLogo.scss";
// import {ReactComponent as Logo} from '../../assets/images/Logo_ic.svg';


export function HeaderLogo() {
    return <Link to={'/dashboard'} className="header-logo">
    {/*    <Logo/>*/}
        <div className="brand-name">Hybrid</div>
    </Link>
}
