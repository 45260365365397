// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/containerStyles/page-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData, getTranslatableInputValues, getTranslationData, initTranslations,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateItem, GetItemById, UpdateItemById} from "../../redux/actions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {validateTranslations} from "../../utils/validateTranslations";
import {Editor} from "../../components/uiElements/Editor";
import {Switch} from "antd";
import {MEDIA_MODAL_TYPES, PAGES} from "../../constants/constTypes";
import {MaterialIcon} from "../../components/utils/Icon";
import Nestable from "react-nestable/dist/Nestable";

class AddEditProgram extends Component {
    constructor(props) {
        super(props);
        this.key = PAGES.program.key;

        this.translationsFields = [
            'title',
            // 'description',
            'options`',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                isHidden: false,
                link: '',
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props.id,
            languageTab: props?.mainLanguage || 'hy',
            requestLoading: false,
            mediaModalType: null,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);

        this.toggleMediaModal = changeStateField.bind(this, 'mediaModalType');

        this.mediaCardWrapper = this.mediaCardWrapper.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);

        this.addOptionItem = this.addOptionItem.bind(this);
        this.deleteOption = this.deleteOption.bind(this);
        this.onOptionPositionChange = this.onOptionPositionChange.bind(this);

        this.addEditItem = this.addEditItem.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        const {id} = this.props;

        if (isEditing) {
            await this.props.GetItemById(this.key, id)
            const translationsData = {};
            const dataById = this.props[this.key + "ById"];

            if (dataById) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = dataById?.translations.find(item => item.language === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        // description: trItem?.description || '',
                        options: trItem?.options || [],
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                mediaMain: dataById?.mediaMain,
                isHidden: dataById?.isHidden,
                link: dataById?.link,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    //medias
    getMedia(mediaArray, mediaType) {
        const {fieldsData, errors, isEditing, mediaModalType} = this.state;
        if (isEditing) {
            this.currentData?.[mediaModalType]?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set(mediaModalType, mediaArray[0]?.id)
                : this.updatedDataMap.delete(mediaModalType);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [mediaModalType]: mediaType === MEDIA_MODAL_TYPES.MEDIA_MAIN
                    ? mediaArray[0] : mediaArray,
            },
            errors: {
                ...errors,
                [mediaModalType]: false
            }
        })
    }

    deleteMedia(type) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.[type] ?
                this.updatedDataMap.set(type, null) :
                this.updatedDataMap.delete(type);

        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: null
            }
        })
    }

    mediaCardWrapper(data) {
        return <MediaFileCard item={data?.item?.media}
                              customDelete={this.deleteMedia.bind(this)}/>
    }

//options
    addOptionItem = () => {
        const {isEditing, fieldsData, languageTab} = this.state;
        const options = fieldsData.translations[languageTab].options || [];
        this.getTranslationValues(
            {
                name: 'options',
                value: ["", ...options],
                haveChanges: isEditing,
            }
        )
    }

    deleteOption(index) {
        const {isEditing, fieldsData, errors, languageTab} = this.state;
        const options = fieldsData.translations[languageTab].options || [];

        const newOptions = options.filter((_, pos) => pos !== index)
        this.getTranslationValues(
            {
                name: 'options',
                value: newOptions,
                haveChanges: isEditing,
            }
        )
    }

    onOptionPositionChange = (items) => {
        const { isEditing} = this.state;
        const options = items.map(item => item.option);
        this.getTranslationValues(
            {
                name: 'options',
                value: options,
                haveChanges: isEditing,
            }
        )
    }

    getOptionValue = (index, value) => {
        const {isEditing, fieldsData, languageTab} = this.state;
        const options = fieldsData.translations[languageTab].options || [];
        const newOptions = options.map((item, pos) => {
            return pos === index ? value : item
        })
        this.getTranslationValues(
            {
                name: 'options',
                value: newOptions,
                haveChanges: isEditing,
            }
        )
    }

    optionItem = (data) => {
        const {index, option} = data.item
        return <div className={'option-item'}>
            <InputGroup inputType="input"
                        type="text"
                        label="Հատկություն"
                        placeholder="Հատկություն"
                        name="option"
                        value={option}
                        maxLength={256}
                        onChange={({value}) => this.getOptionValue(index, value)}/>
            <div className={`delete-btn`} onClick={() => this.deleteOption(index)}>
                <MaterialIcon icon="delete"/>
            </div>
        </div>
    }

    getOptionNestableItems = () => {
        const {fieldsData, languageTab} = this.state
        const translation = fieldsData.translations[languageTab] || {};
        const options=translation.options || [];
        const items = [];
        options && !!options.length && options.forEach((option, index) => {
            items.push({
                id: index,
                index: index,
                option: option
            })
        });
        return items;
    }

//add-edit
    async addEditItem() {
        await this.setState({
            requestLoading: true
        });
        const {translations, mediaMain, isHidden,link} = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        errors.options = {}

        if (result) {
            if (!this.state.isEditing) {
                const reqData = {
                    mediaMain: mediaMain.id,
                    isHidden: isHidden,
                    link,
                    translations: getTranslationData(translations),
                };
                this.props.CreateItem(this.key, reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const reqData = {
                    mediaMain: mediaMain.id,
                    isHidden: isHidden,
                    link,
                    translations: getTranslationData(translations),
                };
                this.props.UpdateItemById(this.key, this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs

            })
        }
    }

    render() {
        const {languageTab, fieldsData, errors, errorsTabs, mediaModalType, requestLoading, isEditing} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        // const dataById = this.props[this.key+"ById"];
        let items = this.getOptionNestableItems();
        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}>
            <section className="page-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար {/*<span className={'required-badge'}>*</span>*/}</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal.bind(this, 'mediaMain')}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={() => this.deleteMedia("mediaMain")}
                    />
                </div>}
                <InputGroup inputType="input"
                            type="text"
                            label="Հղում"
                            placeholder="Հղում"
                            maxLength={100}
                            name="link"
                            required={false}
                            initValue={this.currentData?.link}
                            value={fieldsData?.link}
                            error={errors['link']}
                            onChange={this.getInputValues}/>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={100}
                            required={true}
                            onChange={this.getTranslationValues}/>
                {/*<div className="editor-wrapper">
                    <label>Նկարագրություն</label>
                    <Editor value={currentData?.description || ''}
                            name={'description'}
                            initValue={initData?.description}
                            error={errors['description' + languageTab]}
                            onChange={this.getTranslationValues}/>
                </div>*/}
                <div className={'options-wrapper'}>
                    <label>Հատկություններ </label>
                    <div className={'add-row'}>
                        <LinkButton title="Ավելացնել"
                                    className={`media-select-btn`}
                                    cb={this.addOptionItem}/>
                    </div>
                    {items && items.length !== 0 &&
                    <Nestable
                        items={items}
                        maxDepth={1}
                        onChange={this.onOptionPositionChange}
                        renderItem={this.optionItem}
                    />}
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditItem}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalType}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                mediaType={MEDIA_MODAL_TYPES.MEDIA_MAIN}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateItem,
    UpdateItemById,
    GetItemById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProgram)
