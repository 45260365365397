// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

//import styles

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { validateTranslations } from "../../utils/validateTranslations";
import {GetAboutById, UpdateAboutById} from "../../redux/actions";
import { history } from "../../configs/history";


class AddEditAbout extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description'
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                count: 0
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.addEditAbout = this.addEditAbout.bind(this);
    }

    async componentDidMount () {
        const { id } = this.props;
        const { isEditing, fieldsData } = this.state;
        if (!id) {
            history.push('/about');
            return;
        }

        if (isEditing) {
            const editingData = await this.props.GetAboutById(id);
            const translationsData = {};

            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = editingData?.translations?.find(item => item.language === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                };
            });
            const initStateData = {
                translations: translationsData,
                count: editingData?.count || 0,
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }


    async addEditAbout () {
        await this.setState({
            requestLoading: true
        });
        const { id } = this.props;
        const {count, translations} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = { ...validationTr.errors };
        if(!count){
            result=false;
            errors.count=true;
        }
        if (result) {
                const reqData = {
                    translations: getTranslationData(translations),
                    count: count,
                }
                this.props.UpdateAboutById(id, reqData)
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render () {
        const { fieldsData, errors, languageTab, requestLoading, isEditing, errorsTabs } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}>
                <div className="info-wrapper">
                    <InputGroup inputType="input"
                                type="text"
                                integerNumber={true}
                                label="Քանակ"
                                placeholder="Քանակ"
                                name="count"
                                required={true}
                                initValue={this.currentData?.count}
                                value={fieldsData?.count}
                                error={errors['count']}
                                onChange={this.getInputValues}/>

                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անուն"
                                placeholder="Անուն"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditAbout}/>
                </div>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetAboutById,
    UpdateAboutById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAbout)
