import {_hostMedia} from "../redux/api";
import { defaultEmptyLogo} from "../assets/images";

export function generateImageMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? encodeURI(`${_hostMedia}/images${path}`) : defaultEmptyLogo;
}

export function generateFileMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? encodeURI(`${_hostMedia}/resources${path}`) : defaultEmptyLogo;
}
