// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'

import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetAboutData,
} from "../../redux/actions";
import ItemsList from "../../components/ItemsList";

class About extends Component {
    componentDidMount() {
        this.props.GetAboutData();
    }

    render() {
        let {aboutData, mainLanguage, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անվանում"],
            largeItems: [0, 2, 3],
            status: true,
            actions: ["edit", "delete"],
        };

        let itemListInfo = aboutData?.map((about) => {
            const {id, translations, isHidden} = about;
            const mainTranslations = translations?.find(item => item.language === mainLanguage);
            const {title} = mainTranslations || {};
            return {
                id,
                values: [
                    title ?? '-',
                ],
                status: !isHidden,
            }
        });
        return <PageWrapper pageTitle={'Մեր Մասին'}>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       loading={requestLoading}
                       editLink={"/about/edit"}/>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetAboutData,
};

export default connect(mapStateToProps, mapDispatchToProps)(About)
